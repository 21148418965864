/* eslint-disable no-restricted-globals, no-undef, @typescript-eslint/no-unused-vars */

const TARGET_ORIGIN = '*';
const MESSAGE_EVENT_NAME = 'message';
const EVENT_TYPE = {
  getStorageData: 'GET_STORAGE_DATA',
  storageData: 'STORAGE_DATA',
};
const GET_STORAGE_DATA_REQUEST = {
  type: EVENT_TYPE.getStorageData,
  payload: {
    cookies: [
      'authToken',
      'refreshToken',
    ],
    localStorage: [
      'operator',
      'roles',
      'companyId',
      'companyName',
      'language',
    ],
  },
};

let cusboSideWidgetExtensionStorageEventData = null;

function getCusboSideWidgetExtensionStorageEventData() {
  return cusboSideWidgetExtensionStorageEventData;
}

parent.postMessage(GET_STORAGE_DATA_REQUEST, TARGET_ORIGIN);
window.addEventListener(MESSAGE_EVENT_NAME, (event) => {
  if (event.data.type === EVENT_TYPE.storageData) {
    cusboSideWidgetExtensionStorageEventData = event.data.payload;
  }
});
